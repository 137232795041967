define([
    'lodash'
], function (
    _
) {
    'use strict';

    const COMP_TYPE = {
        SECTION: 'SECTION',
        REF_COMP: 'REF_COMP',
        HEADER: 'HEADER',
        FOOTER: 'FOOTER',
        PINNED: 'PINNED',
        MAIN: 'MAIN',
        OTHER: 'OTHER'
    };

    const Z_INDEX_MIN = 50;
    const Z_INDEX_MAX = 51;

    // eslint-disable-next-line complexity
    const reorderDOMForA11y = groupedComponents => {
        const groupedComponentsWoPinned = groupedComponents.filter(groupedComponent => groupedComponent.type !== COMP_TYPE.PINNED);
        const zIndexesMap = {
            [COMP_TYPE.HEADER]: null,
            [COMP_TYPE.FOOTER]: null,
            [COMP_TYPE.PINNED]: null
        };
        // ASSUMING THERE IS ONE OF HEADER/FOOTER
        // This assupmtion will set all headers at the same z-index, non grouped at the top of the dom
        // Same for footers at the bottom
        const headerIndex = _.findIndex(groupedComponentsWoPinned, {type: COMP_TYPE.HEADER});
        const footerIndex = _.findIndex(groupedComponentsWoPinned, {type: COMP_TYPE.FOOTER});

        // If 1 section is above, all of them will be
        // Sections cannot be above footer!
        const firstSectionIndex = _.findIndex(groupedComponentsWoPinned, {type: COMP_TYPE.SECTION});

        // If header exists, and is not the first one, and has a section above it:
        // Put the minimal z index on it, and increase it afterwards
        if (headerIndex > -1 && headerIndex !== 0 && firstSectionIndex < headerIndex) {
            zIndexesMap[COMP_TYPE.HEADER] = Z_INDEX_MIN;
            zIndexesMap[COMP_TYPE.FOOTER] = Z_INDEX_MAX;
        }

        // If footer exists, and header exists, and the footer is before the header:
        // Put a higher z-index on the header, and 
        if (footerIndex > -1 && headerIndex > -1 && footerIndex < headerIndex) {
            zIndexesMap[COMP_TYPE.HEADER] = Z_INDEX_MAX;
            zIndexesMap[COMP_TYPE.FOOTER] = Z_INDEX_MIN;
        }

        // Set pinned components z-index to the maximum set before or default to MIN
        zIndexesMap[COMP_TYPE.PINNED] = zIndexesMap[COMP_TYPE.HEADER] && zIndexesMap[COMP_TYPE.FOOTER] ? Math.max(zIndexesMap[COMP_TYPE.HEADER], zIndexesMap[COMP_TYPE.FOOTER]) : Z_INDEX_MIN;

        const sections = groupedComponents.filter(groupedComponent => groupedComponent.type === COMP_TYPE.SECTION);
        const pinned = groupedComponents.filter(groupedComponent => groupedComponent.type === COMP_TYPE.PINNED).map(groupedComponent => Object.assign(groupedComponent, {zIndex: zIndexesMap[COMP_TYPE.PINNED]}));
        // Possible hack, set tagname explicitly for headers/footers if there is more than one, and group them under one Header/footer
        const headers = groupedComponents.filter(groupedComponent => groupedComponent.type === COMP_TYPE.HEADER).map(groupedComponent => Object.assign(groupedComponent, {zIndex: zIndexesMap[COMP_TYPE.HEADER]}));
        const footers = groupedComponents.filter(groupedComponent => groupedComponent.type === COMP_TYPE.FOOTER).map(groupedComponent => Object.assign(groupedComponent, {zIndex: zIndexesMap[COMP_TYPE.FOOTER]}));
        const otherElements = groupedComponents.filter(groupedComponent => groupedComponent.type === COMP_TYPE.OTHER);

        return [...headers, {type: COMP_TYPE.MAIN, comp: sections}, ...footers, ...pinned, ...otherElements];
    };

    const groupChildren = (children, fixedChildrenIDs, pageComponentTypes) => children.map(child => {
        if (fixedChildrenIDs.includes(child.props.id)) {
            return {type: COMP_TYPE.PINNED, comp: child};
        } else if (pageComponentTypes[child.props.id] === 'responsive.components.Section') {
            return {type: COMP_TYPE.SECTION, comp: child};
        } else if (pageComponentTypes[child.props.id] === 'responsive.components.HeaderSection') {
            return {type: COMP_TYPE.HEADER, comp: child};
        } else if (pageComponentTypes[child.props.id] === 'responsive.components.FooterSection') {
            return {type: COMP_TYPE.FOOTER, comp: child};
        }
        return {type: COMP_TYPE.OTHER, comp: child};
    });

    
    return {
        COMP_TYPE,
        groupChildren,
        reorderDOMForA11y
    };
});